import { Grid, Stack } from '@mantine/core';
import Image from 'next/image';

const Layout = ({ children }) => {
  return (
    <Grid gutter={0}>
      <Grid.Col
        span={{
          sm: 6,
          xs: 12,
        }}
      >
        <Stack justify="center" align="center" bg="#252727" h="100vh">
          <Image src="/assets/logo.png" alt="logo" width={256} height={109} />
        </Stack>
      </Grid.Col>

      <Grid.Col
        span={{
          sm: 6,
          xs: 12,
        }}
      >
        <Stack align="center">{children}</Stack>
      </Grid.Col>
    </Grid>
  );
};

export default Layout;
