import { supabase } from '@/utils/supabase/client';
import { AuthError } from '@supabase/supabase-js';

const signIn = async ({ email, password }): Promise<any> => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

const signUp = async ({ email, password, options }): Promise<any> => {
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
    options,
  });

  if (error) {
    throw new Error(error.message);
  }
  return data;
};
const forgotPassword = async ({ email }: { email: string }): Promise<AuthError> => {
  const { error } = await supabase.auth.resetPasswordForEmail(email);

  if (error) {
    throw new Error(error.message);
  }
  return error;
};

const verifyOtp = async ({ email, token, type }): Promise<AuthError> => {
  const { error } = await supabase.auth.verifyOtp({
    email,
    token,
    type,
  });
  if (error) {
    throw new Error(error.message);
  }
  return error;
};

const newPassword = async ({ password }): Promise<AuthError> => {
  const { error } = await supabase.auth.updateUser({
    password,
  });
  return error;
};

export { signIn, signUp, verifyOtp, forgotPassword, newPassword };
