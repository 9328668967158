import * as Yup from 'yup';

const LOGIN_SCHEMA = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});

const REGISTER_SCHEMA = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().min(6, 'Password should be at least 6 characters long').required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

const RESET_PASSWORD_SCHEMA = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
});

const NEW_PASSWORD_SCHEMA = Yup.object().shape({
  password: Yup.string().min(6, 'Password should be at least 6 characters long').required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

const OTP_CODE = Yup.object().shape({
  otp: Yup.string().min(6, 'OTP must have 6 charaters').required('Please enter a 6-digit OTP code.'),
});

export { LOGIN_SCHEMA, REGISTER_SCHEMA, RESET_PASSWORD_SCHEMA, NEW_PASSWORD_SCHEMA, OTP_CODE };
