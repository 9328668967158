'use client';

import { signIn } from '@/api/auth';
import Layout from '@/components/auth/layout';
import { LOGIN_SCHEMA } from '@/schema/auth';
import { ActionIcon, Box, Button, Stack, TextInput, Title, Group, Anchor, Center, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import React, { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const Login = () => {
  const router = useRouter();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const loginMutation = useMutation({
    mutationFn: (values: any) => signIn(values),
    onError: (error: any) => {
      notifications.show({
        title: 'Error',
        message: error.message,
      });
    },
    onSuccess: () => {
      router.push('/app');
    },
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LOGIN_SCHEMA,
    onSubmit: async values => {
      loginMutation.mutateAsync(values);
    },
  });

  return (
    <Layout>
      <Stack justify="center" w="100%" h="100vh" align="center" px="sm">
        <Box
          w="100%"
          px={{
            sm: 'lg',
            md: 100,
            lg: 150,
          }}
        >
          <Title order={3} mb="xl">
            Login
          </Title>
          {/* @ts-ignore */}
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <Stack gap="md">
              <TextInput
                label="Email address"
                value={formik?.values.email}
                onChange={formik?.handleChange}
                name="email"
                error={formik.touched.email && formik.errors.email}
                size="md"
                variant="filled"
              />
              <TextInput
                size="md"
                variant="filled"
                type={passwordVisible ? 'text' : 'password'}
                label="Password"
                value={formik?.values.password}
                onChange={formik?.handleChange}
                name="password"
                error={formik.touched.password && formik.errors.password}
                rightSection={
                  <ActionIcon variant="transparent" onClick={togglePasswordVisibility}>
                    {passwordVisible ? <FiEyeOff /> : <FiEye />}
                  </ActionIcon>
                }
              />
              <Group justify="flex-end">
                <Anchor c="pivit" underline="always" component={Link} href="/auth/forgot-password">
                  Forgot Password?
                </Anchor>
              </Group>

              <Button
                loading={loginMutation.isLoading}
                loaderProps={{
                  type: 'bars',
                }}
                fullWidth
                radius="md"
                size="md"
                type="submit"
                disabled={loginMutation.isLoading}
              >
                Login
              </Button>
            </Stack>
          </form>
          <Center mt="xl">
            <Group gap={5}>
              <Text>Need an account? </Text>
              <Anchor component={Link} c="pivit" href="/auth/register">
                Register
              </Anchor>
            </Group>
          </Center>
        </Box>
      </Stack>
    </Layout>
  );
};

export default Login;
